<template>
    <div>
        <div class="content"  style="float:left">
            <div class="item">
                <span class="title">卡券名称：</span>
                <span class="info">{{ cardInfo.productTitle }}</span>
            </div>
            <div class="item">
                <span class="title">订单编号：</span>
                <span class="info">{{ cardInfo.orderNo }}</span>
            </div>
            <div class="item">
                <span class="title">提交用户：</span>
                <span class="info">{{ cardInfo.userName }}</span>
            </div>
            <div class="item">
                <span class="title">提交时间：</span>
                <span class="info">{{ cardInfo.updateTime }}</span>
            </div>
            <div class="item">
                <span class="title">单价：</span>
                <span class="info">￥{{ cardInfo.productPrice }}</span>
            </div>
            <div class="item">
                <span class="title">订单总价：</span>
                <span class="info">￥{{ cardInfo.amountTotal }}</span>
            </div>
            <div class="item">
                <span class="title">订单状态：</span>
                <span class="info">{{ cardInfo.status == 0 ? '待审核' : cardInfo.status == 1 ? '交易中' : cardInfo.status == 2 ? '纠纷中' :
                        cardInfo.status == 3 ? '已关闭' : cardInfo.status == 4 ? '已完成' : ''
                }}</span>
            </div>
          <div class="item">
            <span class="title">卡券数：</span>
            <span class="info">{{ cardInfo.remark }}个</span>
          </div>
        </div>

      <div class="content"  style="float:left">
        <span class="title">卡券编号：</span>
          <span v-for="item in cardInfo.cardNoList">{{ item }}</span>
      </div>
    </div>
</template>

<script>
import { orderDetails } from '@/request/api'
export default {
    data() {
        return {
            cardInfo: null
        }
    },
    created() {
        this.getDetails(this.$route.query.id)
    },
    methods: {
        getDetails(id) {
            orderDetails({ id }).then(res => {
                if (res.code == 200) {
                    this.cardInfo = res.data
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;

    .item_img {
        display: flex;
        align-items: center;

        img {
            width: 100px;
            height: 100px;
        }

        span {
            width: 200px;
        }
    }

    .item {
        margin-top: 20px;
        display: flex;
        align-items: center;

        span {
            display: block;
        }

        .title{
            width: 200px;
        }

        .info {
            width: 500px;
            text-align: left;
            padding: 5px 10px;
        }

        .cardNoList {
            flex-direction: column;
        }
    }
}
</style>