<template>
    <div>
        <el-row :gutter="20">
            <el-col :span="4">
                <div class="title_list" @mouseleave="editCategoryShow=false">
                    <div class="item" :class="Tindex == index ? 'active' : ''" v-for="(item, index) in titleList"
                        :key="index" @click="titleClick(index, item.id)"
                        @contextmenu.prevent="rightClick($event, item.id)">{{ item.title }}</div>
                    <el-button size="small" class="title_addBtn" type="primary" plain @click="addCategory">新增分类
                    </el-button>
                    <div class="edit_category" :style="{ 'top': layerX + 'px', 'left': layerY + 'px' }"
                        v-if="editCategoryShow">
                        <span @click="deleteCategory">删除分类</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="20">
                <div class="list">
                    <div class="top_select">
                        <div class="item">
                            <el-button type="primary" plain @click="newlyAdded">新增商品</el-button>
                        </div>
                        <div class="item">
                            <el-input v-model="listSearch.title" placeholder="请输入商品名称"></el-input>
                        </div>
                        <div class="item">
                            <el-button type="primary" @click="getDataList">查询</el-button>
                        </div>
                        <div class="item">
                            <el-button type="warning" plain @click="selectReset">重置</el-button>
                        </div>
                    </div>
                    <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" height="700"
                        style="width: 100%" @selection-change="handleSelectionChange">
                        <el-table-column label="编号" align="center" width="50">
                            <template slot-scope="scop">
                                {{ scop.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column label="日期" align="center" width="200">
                            <template slot-scope="scope">
                                <img class="list_img" :src="scope.row.img" alt="">
                            </template>
                        </el-table-column>
                        <el-table-column prop="title" label="名称" align="center">
                        </el-table-column>
                        <el-table-column prop="subtitle" label="副标题" show-overflow-tooltip align="center" width="150">
                        </el-table-column>
                        <el-table-column prop="price" label="单价" show-overflow-tooltip align="center" width="80">
                        </el-table-column>
                        <el-table-column prop="inventory" label="库存" show-overflow-tooltip align="center" width="80">
                        </el-table-column>
                        <el-table-column label="是否上架" align="center" width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.status == 1 ? '是' : '否' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="Top推荐" align="center" width="80">
                            <template slot-scope="scope">
                                <span>{{ scope.row.recommend == 1 ? '已推荐' : '不推荐' }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="validity" label="有效期说明" show-overflow-tooltip align="center" width="100">
                        </el-table-column>
                        <el-table-column label="操作" align="center" width="200">
                            <template slot-scope="scope">
                                <el-button size="mini" plain type="primary"
                                    @click="handleExamine(scope.$index, scope.row)">
                                    编辑</el-button>
                                <el-button size="mini" plain type="danger"
                                    @click="handleDelete(scope.$index, scope.row)">删除
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="listSearch.pageNum" :page-sizes="[10, 20, 30, 40, 50]"
                        :page-size="listSearch.pageSize" layout="total, sizes, prev, pager, next, jumper"
                        :total="listSearch.listTotal">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
        <el-dialog title="编辑" :visible.sync="editDialog" width="30%" @close="dialogClose">
            <div class="item_info">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="商品图片" prop="img">
                        <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
                            :on-success="onSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <!-- <el-form-item label="商品分类" prop="title">
                        <el-select v-model="value" placeholder="请选择商品分类">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="商品名称" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="商品副标题" prop="subtitle">
                        <el-input v-model="ruleForm.subtitle"></el-input>
                    </el-form-item>
                    <el-form-item label="单价" prop="price">
                        <el-input v-model="ruleForm.price"></el-input>
                    </el-form-item>
                    <el-form-item label="库存" prop="inventory">
                        <el-input v-model="ruleForm.inventory"></el-input>
                    </el-form-item>
                    <el-form-item label="是否上架" prop="status">
                        <el-switch v-model="ruleForm.status" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="Top推荐" prop="status">
                        <el-switch v-model="ruleForm.recommend" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="有效期说明" prop="validity">
                        <el-input type="textarea" v-model="ruleForm.validity"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">确认</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="新增" :visible.sync="addDialog" width="30%">
            <div class="item_info">
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="商品图片" prop="img">
                        <el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false"
                            :on-success="onSuccess" :before-upload="beforeAvatarUpload">
                            <img v-if="ruleForm.img" :src="ruleForm.img" class="avatar" />
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </el-form-item>
                    <!-- <el-form-item label="商品分类" prop="title">
                        <el-select v-model="value" placeholder="请选择商品分类">
                            <el-option v-for="item in options" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item label="商品名称" prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="商品副标题" prop="subtitle">
                        <el-input v-model="ruleForm.subtitle"></el-input>
                    </el-form-item>
                    <el-form-item label="单价" prop="price">
                        <el-input v-model="ruleForm.price"></el-input>
                    </el-form-item>
                    <el-form-item label="库存" prop="inventory">
                        <el-input v-model="ruleForm.inventory"></el-input>
                    </el-form-item>
                    <el-form-item label="是否上架" prop="status">
                        <el-switch v-model="ruleForm.status" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="Top推荐" prop="status">
                        <el-switch v-model="ruleForm.recommend" active-value="1" inactive-value="0"></el-switch>
                    </el-form-item>
                    <el-form-item label="有效期说明" prop="validity">
                        <el-input type="textarea" v-model="ruleForm.validity"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addSubmit('ruleForm')">立即创建</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
        <el-dialog title="新增分类" :visible.sync="addDialogVisible" width="30%" :before-close="handleClose">
            <div>
                <el-form :model="ruleForm1" :rules="rules1" ref="ruleForm1" label-width="100px" class="demo-ruleForm">
                    <el-form-item label="商品标题" prop="title">
                        <el-input v-model="ruleForm1.title"></el-input>
                    </el-form-item>
                    <el-form-item label="商品副标题" prop="subtitle">
                        <el-input v-model="ruleForm1.subtitle"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="addTitleSubmitForm('ruleForm1')">立即创建</el-button>
                        <el-button @click="addTitleResetForm('ruleForm1')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { commodityTitleList, commodityList, putCommodity, addCommoduty, deleteCommodity, addCommodutyTitle, deletaCommoduyuTile } from '@/request/api'
export default {
    data() {
        return {
            uploadUrl: 'https://card.hubaisite.cn/api/upload/fileImg',
            listSearch: {
                pageNum: 1,
                pageSize: 10,
                listTotal: 0
            },
            Tindex: 0,
            titleList: [],
            tableData: [],
            multipleSelection: [],
            editDialog: false,
            addDialog: false,
            fileList: [],
            ruleForm: {
                createTime: "",
                delFlag: "",
                id: 0,
                img: "",
                inventory: 0,
                parentId: 0,
                price: 0,
                productExplain: "",
                recommend: "",
                status: "",
                subtitle: "",
                title: "",
                updateTime: "",
                validity: ""
            },
            options: [],
            rules: {
                img: [
                    { required: true, message: '请上传商品图片', trigger: 'blur' }
                ],
                title: [
                    { required: true, message: '请输入商品名称', trigger: 'blur' }
                ],
                subtitle: [
                    { required: true, message: '请输入商品副标题', trigger: 'blur' }
                ],
                price: [
                    { required: true, message: '请输入商品单价', trigger: 'blur' }
                ],
                inventory: [
                    { required: true, message: '请输入库存数量', trigger: 'blur' }
                ],
                validity: [
                    { required: true, message: '请填写有效期说明', trigger: 'blur' }
                ]
            },
            addDialogVisible: false,
            editCategoryShow: false,
            rightClickId: '',
            layerX: 0,
            layerY: 0,
            ruleForm1: {
                title: '',
                subtitle: ''
            },
            rules1: {
                title: [
                    { required: true, message: '请填写商品标题', trigger: 'blur' }
                ],
                subtitle: [
                    { required: true, message: '请填写商品副标题', trigger: 'blur' }
                ]
            }
        }
    },
    created() {
        this.getTitleList()
    },
    methods: {
        addCategory() {
            this.editCategoryShow=false
            this.addDialogVisible = true
        },
        addTitleSubmitForm(formName) {
            let { title, subtitle } = this.$data.ruleForm1
            let params = {
                title, subtitle
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addCommodutyTitle(params).then(res => {
                        if (res.code == 200) {
                            this.addDialogVisible = false
                            this.$message.success('新增成功')
                            this.getTitleList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        addTitleResetForm(formName) {
            this.$refs[formName].resetFields();
        },
        deleteCategory() {
            this.editCategoryShow=false
            this.$confirm('此操作将会永久删除, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                deletaCommoduyuTile({ id: this.rightClickId }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功')
                        this.getTitleList()
                        this.editCategoryShow = false
                        this.Tindex=0
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        handleClose() {
            this.addDialogVisible = false
        },
        rightClick(e, id) {
            console.log('id :>> ', id);
            this.editCategoryShow = true
            this.rightClickId = id
            this.layerX = e.pageY + 10
            this.layerY = e.pageX + 20
        },
        newlyAdded() {
            this.addDialog = true
        },
        onSuccess(file, fileList) {
            this.ruleForm.img = file.data
        },
        beforeAvatarUpload(file) {
            const isLt1M = file.size / 1024 / 1024
            if (isLt1M > 1) {
                this.$message.error('上传头像图片大小不能超过1MB')
            }
            return isLt1M
        },
        titleClick(index, id) {
            this.editCategoryShow = false
            this.Tindex = index
            this.listSearch.parentId = this.titleList[index].id
            this.getDataList()
            this.ruleForm.parentId = id
        },
        getTitleList() {
            commodityTitleList({ parentId: 0 }).then(res => {
                this.titleList = res.data
                this.listSearch.parentId = res.data[0].id
                this.ruleForm.parentId = res.data[0].id
                this.getDataList()
            })
        },
        getDataList() {
            let { pageNum, pageSize, parentId, title } = this.$data.listSearch
            let params = {
                pageNum,
                pageSize,
                parentId,
                title,
                sort: 1
            }
            commodityList(params).then(res => {
                this.tableData = res.data.list
                this.listSearch.listTotal = res.data.total
            })
        },
        selectReset() {
            this.listSearch = {
                pageNum: 1,
                pageSize: 10,
                listTotal: 0
            }
            this.getDataList()
        },
        // 编辑
        handleExamine(index, row) {
            this.editDialog = true
            this.ruleForm = row
        },
        // 编辑（提交）
        submitForm(formName) {
            let { id, img, inventory, parentId, price, productExplain, recommend, status, subtitle, title, validity } = this.$data.ruleForm
            let params = {
                id, img, inventory, parentId, price, productExplain, recommend, status, subtitle, title, validity
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    putCommodity(params).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功')
                            this.editDialog = false
                            this.getDataList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 新增（提交）
        addSubmit(formName) {
            let { id, img, inventory, parentId, price, productExplain, recommend, status, subtitle, title, validity } = this.$data.ruleForm
            let params = {
                id, img, inventory, parentId, price, productExplain, recommend, status, subtitle, title, validity
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    addCommoduty(params).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功')
                            this.addDialog = false
                            this.dialogClose()
                            this.getDataList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        // 编辑（重置）
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        // 删除
        handleDelete(index, row) {
            this.$confirm('此操作将修改订单状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                deleteCommodity({ id: row.id }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('删除成功')
                        this.getDataList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        dialogClose() {
            this.ruleForm = {
                createTime: "",
                delFlag: "",
                id: 0,
                img: "",
                inventory: 0,
                parentId: 0,
                price: 0,
                productExplain: "",
                recommend: "",
                status: "",
                subtitle: "",
                title: "",
                updateTime: "",
                validity: ""
            }
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSizeChange(val) {
            this.listSearch.pageSize = val
            this.getDataList()
        },
        handleCurrentChange(val) {
            this.listSearch.pageNum = val
            this.getDataList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style lang="less" scoped>
.title_list {
    margin-top: 20px;
    width: 90%;
    padding: 5%;
    height: 750px;
    box-shadow: 0 0 10px 0 #eee;
    border-radius: 6px;
    overflow-y: scroll;
    position: relative;
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    position: relative;

    .edit_category {
        position: fixed;
        top: 0;
        left: 0;
        padding: 10px;
        width: 100px;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 0 10px 0 #aaa;
        z-index: 99999999;

        span {
            display: block;
            padding: 10px;
            cursor: pointer;
        }

        span:hover {
            background-color: #bad8f7;
        }
    }

    .title_addBtn {
        width: 80%;
        position: absolute;
        bottom: 10px;
        left: 10%;
    }

    .item {
        padding: 10px 0;
        cursor: pointer;
    }

    .active {
        background-color: #f2f2f2;
        color: #66b1ff;
    }
}

.title_list::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}

.list_img {
    width: 100px;
    height: 60px;
}

.top_select {
    margin: 20px 0;
    display: flex;

    .item {
        margin-right: 20px;
    }

    .el-input {
        width: 200px;
    }
}

.item_info {
    .demo-ruleForm {
        .avatar-uploader {
            width: 100px;
            height: 100px;
            border: 1px solid #eee;
            border-radius: 5px;
        }

        .avatar,
        .el-icon-plus {
            width: 100px;
            height: 100px;
            text-align: center;
            line-height: 100px;
        }
    }
}

::v-deep .is-scrolling-none::-webkit-scrollbar {
    display: none;
}

::v-deep.el-table--scrollable-y ::-webkit-scrollbar {
    display: none;
}

::v-deep.el-table--scrollable-x ::-webkit-scrollbar {
    display: none;
}

::v-deep .el-table .el-table__cell {
    padding: 4px 0 !important;
}

::v-deep .el-button--primary.is-plain {
    background-color: #409EFF;
    color: #fff;
}

::v-deep .el-button--danger.is-plain {
    background-color: #F56C6C;
    color: #fff;
}

.el-pagination {
    margin-top: 10px;
}
</style>