<template>
    <div>
        <div class="top_select">
            <el-input v-model="orderSearch.userName" placeholder="请输入用户名称"></el-input>
            <el-button type="primary" @click="getDataList">查询</el-button>
            <el-button type="warning" plain @click="selectReset">重置</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange">
            <!-- <el-table-column label="编号" align="center" width="50">
                <template slot-scope="scop">
                    {{ scop.$index + 1 }}
                </template>
            </el-table-column> -->
            <el-table-column prop="userId" label="用户ID" align="center" width="80">
            </el-table-column>
            <el-table-column prop="userName" label="提交用户" align="center" width="120">
            </el-table-column>
            <el-table-column prop="amountTotal" label="可提现金额" align="center" width="200">
            </el-table-column>
            <el-table-column prop="withdrawTotal" label="申请提现金额" show-overflow-tooltip align="center" width="200">
            </el-table-column>
            <el-table-column prop="collectionAccount" label="收款账号" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="收款类型" align="center" width="120">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.type == 1" type="primary">支付宝</el-tag>
                    <el-tag v-show="scope.row.type == 2" type="success">微信</el-tag>
                    <el-tag v-show="scope.row.type == 3" class="bank_card">银行卡</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="状态" align="center" width="120">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.status == 0" type="warning">待提现</el-tag>
                    <el-tag v-show="scope.row.status == 1" type="success">已完成</el-tag>
                    <el-tag v-show="scope.row.status == 2" type="danger">已关闭</el-tag>
                </template>
            </el-table-column>
            <el-table-column label="日期" align="center" width="200">
                <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
            <el-table-column label="操作" align="center" width="300">
                <template slot-scope="scope">
                        <el-button size="mini" v-if="scope.row.status == 0" plain type="primary"
                            @click="handleExamine(scope.$index, scope.row)">审核</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="orderSearch.pageNum" :page-sizes="[5, 10, 15, 20]" :page-size="orderSearch.pageSize"
            layout="total, prev, pager, next, jumper" :total="orderSearch.listTotal">
        </el-pagination>

        <el-dialog title="审核订单" width="30%" :visible.sync="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="审核类型" :label-width="formLabelWidth" prop="type">
                    <el-select style="width: 100%;" v-model="form.type" placeholder="请选择审核类型">
                        <el-option label="通过" value="1"></el-option>
                        <el-option label="拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核意见" :label-width="formLabelWidth" prop="text">
                    <el-input v-model="form.text" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { Withdrawal, updateWithdrawal } from '@/request/api'
export default {
    data() {
        var textRules = (rule, value, callback) => {
             if (this.form.type==2&&value=='') {
                callback(new Error('请输入审核意见'));
            } else {
                callback();
            }
        }
        return {
            orderSearch: {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0
            },
            tableData: [],
            multipleSelection: [],
            dialogFormVisible: false,
            form: {
                id: '',
                type: '',
                text: '',
            },
            rules: {
                text: [
                    { validator: textRules, trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择审核类型', trigger: 'change' }
                ],
            },
            formLabelWidth: '100px'
        }
    },
    created() {
        this.getDataList()
    },
    methods: {
        // 获取数据
        getDataList() {
            let { pageNum, pageSize,userName } = this.$data.orderSearch
            let params = {
                pageNum,
                pageSize,
                userName
            }
            Withdrawal(params).then(res => {
                this.tableData = res.data.list
                this.orderSearch.listTotal = res.data.total
            })
        },
        selectReset(){
            this.orderSearch= {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0
            }
            this.getDataList()
        },
        // 审核
        handleExamine(index, row) {
            this.dialogFormVisible = true
            this.form.id = row.id
        },
        submitForm(formName) {
            let { id, type, text } = this.$data.form
            let params = {
                id,
                reason: text,
                status: type,
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    updateWithdrawal(params).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功')
                            this.dialogFormVisible = false
                            this.getDataList()
                            this.$refs[formName].resetFields();
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogFormVisible = false
            this.getDataList()
        },
        // 每页数量
        handleSizeChange(val) {
            this.orderSearch.pageSize = val
            this.getDataList()
        },
        // 选中页码
        handleCurrentChange(val) {
            this.orderSearch.pageNum = val
            this.getDataList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style lang="less" scoped>
.top_select{
    margin: 20px 0;
    width: 23%;
    display: flex;
    justify-content: space-between;
    .el-input{
        width: 200px;
    }
}
::v-deep .el-tag.el-tag--warning{
    background-color: #e6a23c;
    color: #fff;
}
::v-deep .el-tag.el-tag--danger{
    background-color: #f56c6c;
    color: #fff;
}
::v-deep .el-tag.el-tag--primary{
    background-color: #409EFF;
    color: #fff;
}
::v-deep .el-tag.el-tag--success{
    background-color: #67c23a;
    color: #fff;
}
::v-deep .el-button--primary.is-plain{
    background-color: #409EFF;
    color: #fff;
}
::v-deep .el-tag{
    width: 60px;
}
.bank_card{
    background-color: #db66d3;
    color: #fff;
}
::v-deep .el-table .el-table__cell {
    padding: 16px 0 !important;
}
.el-pagination{
   margin-top: 10px;
}
</style>