<template>
    <div>
        <div class="top_select">
            <el-input v-model="logSearch.userName" placeholder="请输入内容"></el-input>
            <el-button type="primary" @click="getOperationLogList">查询</el-button>
            <el-button type="warning" plain @click="selectReset">重置</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55" align="center">-->
<!--            </el-table-column>-->
<!--            <el-table-column label="编号" align="center" width="50">-->
<!--                <template slot-scope="scop">-->
<!--                    {{ scop.$index + 1 }}-->
<!--                </template>-->
<!--            </el-table-column>-->
            <el-table-column prop="operName" label="姓名" align="center" width="120">
            </el-table-column>
            <el-table-column prop="operationDetails" label="操作对象" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="operatorType" label="操作终端" align="center">
            </el-table-column>
            <el-table-column prop="phonenumber" label="手机号码" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="title" label="所属模块" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="businessInfo" label="操作类型" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="日期" align="center" width="200">
                <template slot-scope="scope">{{ scope.row.operTime }}</template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="logSearch.pageNum" :page-sizes="[10, 20, 30, 40, 50]" :page-size="logSearch.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="logSearch.listTotal">
        </el-pagination>
    </div>
</template>

<script>
import { OperationLogList } from '@/request/api'
export default {
    data() {
        return {
            logSearch: {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0
            },
            tableData: [],
            multipleSelection: []
        }
    },
    created() {
        this.getOperationLogList()
    },
    methods: {
        getOperationLogList() {
            let { pageNum, pageSize,userName} = this.$data.logSearch
            let params = {
                pageNum,
                pageSize,
                operName:userName
            }
            OperationLogList(params).then(res => {
                this.tableData = res.rows
                this.logSearch.listTotal=res.total
            })
        },
        selectReset(){
            this.logSearch= {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0
            }
            this.getOperationLogList()
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSizeChange(val) {
            this.logSearch.pageSize=val
            this.getOperationLogList()
        },
        handleCurrentChange(val) {
            this.logSearch.pageNum=val
            this.getOperationLogList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style lang="less" scoped>
.top_select{
    margin: 20px 0;
    width: 25%;
    display: flex;
    justify-content: space-between;
    .el-input{
        width: 200px;
    }
}
::v-deep .el-tag.el-tag--success{
    background-color: #67c23a;
    color: #fff;
}
::v-deep .el-table .el-table__cell {
    padding: 20px 0 !important;
}
.el-pagination{
   margin-top: 10px;
}
</style>