import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Login from '../views/login.vue'
import List from '../views/UserList.vue'
import HomeView from '../views/HomeView.vue'
import Commodity from '../views/CommodityList.vue'
import Order from '../views/OrderList.vue'
import Withdrawal from '../views/WithdrawalList.vue'
import OrderDetails from '../views/orderDetails.vue'
import OperationLog from '../views/OperationLog.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: Login,
    name: 'login',
    hidden: true
},
{
    path: '/',
    component: Home,
    name: '',
    leaf: true,//只有一个节点
    iconCls: 'icon-yingyong2 iconfont', //图标样式class
    children: [
        { path: '/home', component: HomeView, name: '首页' },
    ]
},
{
    path: '/',
    component: Home,
    name: '商品管理',
    leaf: true,//只有一个节点
    iconCls: 'icon-shangpin2 iconfont', //图标样式class
    children: [
        { path: '/commodity', component: Commodity, name: '商品管理' }
    ]
},
{
    path: '/',
    component: Home,
    name: '订单管理',
    leaf: true,//只有一个节点
    iconCls: 'icon-hetongmokuai2 iconfont', //图标样式class
    children: [
        { path: '/order', component: Order, name: '订单管理' },
        { path: '/orderDetails', component: OrderDetails, name: '订单详情' }
    ]
},
{
    path: '/',
    component: Home,
    name: '提现记录',
    leaf: true,//只有一个节点
    iconCls: 'icon-zulin2 iconfont', //图标样式class
    children: [
        { path: '/Withdrawal', component: Withdrawal, name: '提现记录' }
    ]
},
{
    path: '/',
    component: Home,
    name: '用户管理',
    leaf: true,//只有一个节点
    iconCls: 'icon-kehu2 iconfont', //图标样式class
    children: [
        { path: '/list', component: List, name: '用户管理' }
    ]
},
{
    path: '/',
    component: Home,
    name: '操作日志',
    leaf: true,//只有一个节点
    iconCls: 'icon-baobiaoguanli2 iconfont', //图标样式class
    children: [
        { path: '/OperationLog', component: OperationLog, name: '操作日志' }
    ]
}
]
const router = new VueRouter({
  routes
})
  
router.beforeEach((to, from, next) => {
	if (to.path === "/login") {
		next();
	} else {
		const session = sessionStorage.getItem("token") ?? localStorage.getItem("token");
		session ? next() : next("/login");
	}
});
export default router
