<template>
    <div>
        <div class="top_select">
            <el-input v-model="userSearch.userName" placeholder="请输入内容"></el-input>
            <el-button type="primary" @click="getUserList">查询</el-button>
            <el-button type="warning" plain @click="selectReset">重置</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55" align="center">-->
<!--            </el-table-column>-->
            <!-- <el-table-column label="编号" align="center" width="50">
                <template slot-scope="scop">
                    {{ scop.$index + 1 }}
                </template>
            </el-table-column> -->
            <el-table-column prop="userId" label="用户ID" align="center" width="80">
            </el-table-column>
            <el-table-column prop="userName" label="姓名" align="center">
            </el-table-column>
            <el-table-column prop="phonenumber" label="手机号" align="center">
            </el-table-column>
            <el-table-column prop="qqCard" label="QQ号" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="clearingTotal" label="待结算" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="disputeTotal" label="纠纷中" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="availableTotal" label="可提现" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="idCard" label="身份证号" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column label="是否认证" align="center">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.authentication == 0" type="info">未认证</el-tag> 
                    <el-tag v-show="scope.row.authentication == 1" type="success">已认证</el-tag>    
                </template>
            </el-table-column>
            <el-table-column label="日期" align="center" width="200">
                <template slot-scope="scope">{{ scope.row.createTime }}</template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="userSearch.pageNum" :page-sizes="[10, 20, 30, 40, 50]" :page-size="userSearch.pageSize"
            layout="total, sizes, prev, pager, next, jumper" :total="userSearch.listTotal">
        </el-pagination>
    </div>
</template>

<script>
import { UserList } from '@/request/api'
export default {
    data() {
        return {
            userSearch: {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0
            },
            tableData: [],
            multipleSelection: []
        }
    },
    created() {
        this.getUserList()
    },
    methods: {
        getUserList() {
            let { pageNum, pageSize, nickName, phonenumber, roleId, userId, userName } = this.$data.userSearch
            let params = {
                pageNum,
                pageSize,
                userName
            }
            UserList(params).then(res => {
                this.tableData = res.rows
                this.userSearch.listTotal=res.total
            })
        },
        selectReset(){
            this.userSearch= {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0
            }
            this.getUserList()
        },
        toggleSelection(rows) {
            if (rows) {
                rows.forEach(row => {
                    this.$refs.multipleTable.toggleRowSelection(row);
                });
            } else {
                this.$refs.multipleTable.clearSelection();
            }
        },
        handleSizeChange(val) {
            this.userSearch.pageSize=val
            this.getUserList()
        },
        handleCurrentChange(val) {
            this.userSearch.pageNum=val
            this.getUserList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style lang="less" scoped>
.top_select{
    margin: 20px 0;
    width: 25%;
    display: flex;
    justify-content: space-between;
    .el-input{
        width: 200px;
    }
}
::v-deep .el-tag.el-tag--success{
    background-color: #67c23a;
    color: #fff;
}
::v-deep .el-table .el-table__cell {
    padding: 16px 0 !important;
}
.el-pagination{
   margin-top: 10px;
}
</style>