<template>
    <div class="page">
        <div class="login">
            <div class="title">Best卡券后台管理系统</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item prop="phonenumber">
                    <el-input type="numberr" placeholder="请输入手机号" v-model="ruleForm.phonenumber"></el-input>
                </el-form-item>
                <el-form-item prop="msgCode">
                    <div style="display: flex;align-items: center;">
                        <el-input style="width: 200px;" placeholder="请输入验证码" type="password" v-model="ruleForm.msgCode">
                        </el-input>
                        <el-button v-if="vCodeShow" class="Verification_Code" @click="getVerificationCode()">获取验证码
                        </el-button>
                        <div class="Verification_Code" v-else>{{  countDown  }} s</div>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" style="width: 150px;margin-top: 30px;" @click="submitForm('ruleForm')">登录</el-button>
                    <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { login, msgCode } from '@/request/api'
export default {
    data() {
        return {
            ruleForm: {
                phonenumber: '',
                msgCode: '',
            },
            msgCodeText: '获取验证码',
            rules: {
                phonenumber: [
                    { required: true, message: '请输入手机号', trigger: 'blur' }
                ],
                msgCode: [
                    { required: true, message: '请输入验证码', trigger: 'blur' }
                ]
            },
            vCodeShow: true,
            countDown: 60,
        }
    },
    created() {

    },
    methods: {
        getVerificationCode() {
            msgCode({
                phonenumber: this.ruleForm.phonenumber
            }).then(res => {
                if (res.code == 200) {
                    this.vCodeShow = false
                    this.$message.success('验证码已发送')
                    let time = setInterval(() => {
                        if (this.countDown == 0) {
                            clearInterval(time)
                            this.vCodeShow = true
                            this.countDown = 60
                        } else {
                            this.countDown--
                        }
                    }, 1000)
                } else {
                    this.$message.warning(res.msg)
                }
            })
        },
        submitForm(formName) {
            let { phonenumber, msgCode } = this.$data.ruleForm
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    login({
                        phonenumber,
                        msgCode
                    }).then(res => {
                        if (res.code == 200) {
                            this.$message.success('登录成功')
                            localStorage.setItem('token', res.data.token)
                            localStorage.setItem('userInfo',JSON.stringify(res.data))
                            this.$router.push("/home")
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        }
    }
}
</script>

<style lang="less" scoped>
.page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('../assets/login_bg1.jpg');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;

    .login {
        min-width: 400px;
        border-radius: 10px;
        padding: 0 20px;
        background-color: #ffffff30;


        .title {
            padding: 20px;
            font-size: 32px;
            font-weight: bold;
            color: #fff;
        }

        .demo-ruleForm {
            color: #fff;
            width: 350px;
            margin: 0 auto;
            height: 80%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            border-radius: 10px;
            padding: 20px;

            ::v-deep .el-form-item__content {
                margin: 0 !important;
            }

            ::v-deep .el-form-item__label {
                color: #fff;
            }

            ::v-deep .el-input__inner {
                background-color: #00000010;
                border-radius: 20px;
                color: #fff;
                // font-size: 16px;

                input {
                    background: none !important;
                    outline: none !important;
                    border: 0px !important;
                }
            }
        }
    }
    .Verification_Code {
        width: 90px;
        height: 35px;
        background-color: #f2f2f200;
        padding: 0 10px;
        margin-left: 10px;
        color: #fff;
        border-radius: 30px;
    }
    ::v-deep .el-form-item {
        width: 300px;
    }
}
</style>