<template>
    <div>
        <div class="top_select">
            <el-input v-model="orderSearch.userName" placeholder="请输入用户名称"></el-input>
            <el-input v-model="orderSearch.orderNo" placeholder="请输入订单号"></el-input>
            <el-select v-model="value" placeholder="请选择状态" @change="TypeChange">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
            </el-select>
            <el-date-picker v-model="orderSearch.timeVal" type="daterange" align="right" unlink-panels
                @change="timeChange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                :picker-options="pickerOptions2">
            </el-date-picker>
            <el-button type="primary" @click="getOrderList">查询</el-button>
            <el-button type="warning" @click="selectReset">重置</el-button>
            <el-button type="warning" plain @click="exportOrder">导出</el-button>
        </div>
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%"
            @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection" width="55" align="center">-->
<!--            </el-table-column>-->
<!--            <el-table-column label="编号" align="center" width="50">-->
<!--                <template slot-scope="scop">-->
<!--                    {{  scop.$index + 1  }}-->
<!--                </template>-->
<!--            </el-table-column>-->
<el-table-column prop="userId" label="用户ID" align="center" width="80">
            </el-table-column>
            <el-table-column prop="userName" label="提交用户" align="center" width="100">
            </el-table-column>
            <el-table-column prop="productTitle" label="商品名称" show-overflow-tooltip align="center" width="200">
            </el-table-column>
            <el-table-column prop="orderNo" label="订单号" align="center" width="200">
            </el-table-column>
            <el-table-column prop="productPrice" label="单价" show-overflow-tooltip align="center" width="100">
            </el-table-column>
            <el-table-column prop="amountTotal" label="总价" show-overflow-tooltip align="center" width="100">
            </el-table-column>
            <el-table-column label="状态" align="center" width="120">
                <template slot-scope="scope">
                    <el-tag v-show="scope.row.status == 0" type="warning">待审核</el-tag>
                    <el-tag v-show="scope.row.status == 1" type="primary">交易中</el-tag>
                    <el-tag v-show="scope.row.status == 2" type="danger">纠纷中</el-tag>
                    <el-tag v-show="scope.row.status == 3" type="info">已关闭</el-tag>
                    <el-tag v-show="scope.row.status == 4" type="success">已完成</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="reason" label="审核失败原因" show-overflow-tooltip align="center" width="200">
            </el-table-column>
            <el-table-column prop="remark" label="卡券数" show-overflow-tooltip align="center" width="80">
            </el-table-column>
            <el-table-column label="日期" align="center" width="180">
                <template slot-scope="scope">{{  scope.row.createTime  }}</template>
            </el-table-column>
            <el-table-column label="操作" align="left" width="300">
                <template slot-scope="scope">
                    <div v-if="scope.row.status == 3 || scope.row.status == 4">
                        <!-- <el-tag v-show="scope.row.status == 3" type="info">订单已关闭</el-tag>
                        <el-tag v-show="scope.row.status == 4" type="success">订单已完成</el-tag> -->
                        <el-button size="mini" @click="handleDetails(scope.$index, scope.row)">详情
                        </el-button>
                    </div>
                    <div v-else-if="scope.row.status == 2">
                        <el-button size="mini" type="warning" @click="handleClose(scope.$index, scope.row)">关闭
                        </el-button>
                        <el-button size="mini" @click="handleDetails(scope.$index, scope.row)">详情
                        </el-button>
                    </div>
                    <div v-else>
                        <el-button size="mini" @click="handleDetails(scope.$index, scope.row)">详情
                        </el-button>
                        <el-button size="mini" v-if="scope.row.status == 0" type="primary"
                            @click="handleExamine(scope.$index, scope.row)">审核</el-button>
                        <el-button size="mini" v-if="scope.row.status != 0" type="success"
                            @click="handleComplete(scope.$index, scope.row)">完成</el-button>
                        <el-button size="mini" type="warning" @click="handleClose(scope.$index, scope.row)">关闭
                        </el-button>
                        <el-button size="mini" type="danger" v-if="scope.row.status == 1"
                            @click="handleDispute(scope.$index, scope.row)">纠纷
                        </el-button>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
            :current-page="orderSearch.pageNum" :page-sizes="[5, 10, 15, 20]" :page-size="orderSearch.pageSize"
            layout="total, prev, pager, next, jumper" :total="orderSearch.listTotal">
        </el-pagination>

        <el-dialog title="审核订单" width="30%" :visible.sync="dialogFormVisible">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="审核类型" :label-width="formLabelWidth" prop="type">
                    <el-select style="width: 100%;" v-model="form.type" placeholder="请选择审核类型">
                        <el-option label="通过" value="1"></el-option>
                        <el-option label="拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="审核意见" :label-width="formLabelWidth" prop="text">
                    <el-input v-model="form.text" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="纠纷" width="30%" :visible.sync="dialogDispute">
            <el-form :model="form" :rules="rules" ref="ruleForm">
                <el-form-item label="类型" :label-width="formLabelWidth" prop="type">
                    <el-select disabled style="width: 100%;" placeholder="纠纷订单">
                        <el-option label="通过" value="1"></el-option>
                        <el-option label="拒绝" value="2"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="纠纷原因" :label-width="formLabelWidth" prop="text">
                    <el-input v-model="form1.text" auto-complete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="resetForm('ruleForm')">取 消</el-button>
                <el-button type="primary" @click="DisputeSubmitForm('ruleForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { OrderList, updateOrder, orderExport } from '@/request/api'
export default {
    data() {
        var textRules = (rule, value, callback) => {
            if (this.form.type == 2 && value == '') {
                callback(new Error('请输入审核意见'));
            } else {
                callback();
            }
        }
        return {
            options: [{
                value: '0',
                label: '待审核'
            }, {
                value: '1',
                label: '交易中'
            }, {
                value: '2',
                label: '纠纷中'
            }, {
                value: '3',
                label: '已关闭'
            }, {
                value: '4',
                label: '已完成'
            }],
            value: '',
            pickerOptions2: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            orderSearch: {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0,
                status: '',
                orderNo: '',
                timeVal: []
            },
            tableData: [],
            multipleSelection: [],
            dialogFormVisible: false,
            dialogDispute: false,
            form: {
                id: '',
                type: '',
                text: '',
            },
            form1: {
                id: '',
                type: 2,
                text: ''
            },
            rules: {
                text: [
                    { validator: textRules, trigger: 'blur' }
                ],
                type: [
                    { required: true, message: '请选择审核类型', trigger: 'change' }
                ],
            },
            formLabelWidth: '100px'
        }
    },
    created() {
        this.getOrderList()
    },
    methods: {
        // 获取数据
        getOrderList() {
            console.log('this.timeVal :>> ', this.orderSearch.timeVal);
            let { pageNum, pageSize, userName, status, orderNo, timeVal } = this.$data.orderSearch
            let params = {
                pageNum,
                pageSize,
                userName,
                status,
                orderNo,
                startTime: timeVal[0],
                endTime: timeVal[1]
            }
            OrderList(params).then(res => {
                this.tableData = res.data.list
                this.orderSearch.listTotal = res.data.total
            })
        },
        timeChange(value) {
            value.forEach((item, index) => {
                let y = item.getFullYear()
                let m = item.getMonth() + 1 > 9 ? item.getMonth() + 1 : '0' + (item.getMonth() + 1)
                let d = item.getDate() > 9 ? item.getDate() : '0' + item.getDate()
                this.orderSearch.timeVal[index] = y + '-' + m + '-' + d
            })
        },
        // 格式化时间
        formatTime(time) {
            let date = new Date(time)
            let y = date.getFullYear()
            let m = date.getMonth() + 1
            let d = date.getDate()
            return y + '-' + m + '-' + d
        },
        // 重置
        selectReset() {
            this.orderSearch = {
                pageNum: 1,
                pageSize: 10,
                nickName: '',
                phonenumber: '',
                roleId: '',
                userId: '',
                userName: '',
                listTotal: 0,
                status: '',
                orderNo: '',
                timeVal: []
            }
            this.value = ''
            this.getOrderList()
        },
        // 导出
        exportOrder() {
            let { userName, orderNo, status } = this.$data.orderSearch
            orderExport({ userName, orderNo, status }).then(res => {
                // window.open(res.data)
                // console.log(url);
                let link = document.createElement("a");
                //创建一个a标签
                link.style.display = "none";
                //将a标签隐藏
                link.href = res.data;
                //给a标签添加下载链接  "域名+接口"  safe是一个动态的域名  后面的接口替换成你自己的下载接口
                link.setAttribute("download", name);
                // 此处注意，要给a标签添加一个download属性，属性值就是文件名称 否则下载出来的文件是没有属性的，空白白
                document.body.appendChild(link);
                //将上面创建的a标签加入到body的尾部
                link.click();
                //执行a标签
            })
        },
        TypeChange(value) {
            this.orderSearch.status = value
        },
        // 详情
        handleDetails(index, row) {
            console.log('row :>> ', row);
            this.$router.push({ path: '/orderDetails', query: { id: row.id } })
        },
        // 审核
        handleExamine(index, row) {
            this.dialogFormVisible = true
            this.form.id = row.id
        },
        // 提交
        submitForm(formName) {
            let { id, type, text } = this.$data.form
            let params = {
                id,
                reason: text,
                status: type,
            }
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    updateOrder(params).then(res => {
                        if (res.code == 200) {
                            this.$message.success('提交成功')
                            this.dialogFormVisible = false
                            this.getOrderList()
                        } else {
                            this.$message.error(res.msg)
                        }
                    })
                } else {
                    return false;
                }
            });
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields();
            this.dialogFormVisible = false
            this.dialogDispute = false
            this.getOrderList()
        },
        // 完成
        handleComplete(index, row) {
            this.$confirm('此操作将修改订单状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                updateOrder({
                    id: row.id,
                    status: 4,
                }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('已关闭')
                        this.getOrderList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // 关闭
        handleClose(index, row) {
            this.$confirm('此操作将修改订单状态, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            }).then(() => {
                updateOrder({
                    id: row.id,
                    status: 3,
                }).then(res => {
                    if (res.code == 200) {
                        this.$message.success('已关闭')
                        this.getOrderList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        // 纠纷
        handleDispute(index, row) {
            this.dialogDispute = true
            this.form1.id = row.id
        },
        DisputeSubmitForm() {
            let { id, type, text } = this.$data.form1
            this.dialogDispute=false
            updateOrder({
                id,
                status: type,
                reason: text
            }).then(res => {
                if (res.code == 200) {
                    this.$message.success('已提交')
                    this.getOrderList()
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        // 每页数量
        handleSizeChange(val) {
            this.orderSearch.pageSize = val
            this.getOrderList()
        },
        // 选中页码
        handleCurrentChange(val) {
            this.orderSearch.pageNum = val
            this.getOrderList()
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        }
    }
}
</script>

<style lang="less" scoped>
.top_select {
    min-width: 500px;
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;

    .el-input,
    .el-select {
        width: 200px;
        margin-right: 20px;
    }

    .el-date-editor {
        margin-right: 20px;
    }
}

::v-deep .el-tag.el-tag--danger {
    background-color: red;
    color: #fff;
}

::v-deep .el-tag.el-tag--success {
    background-color: rgb(162, 239, 86);
    color: #fff;
}

::v-deep .el-tag.el-tag--warning {
    background-color: #fd9902;
    color: #fff;
}

::v-deep .el-tag.el-tag--primary {
    background-color: rgb(58, 221, 167);
    color: #fff;
}

::v-deep .el-table .el-table__cell {
    padding: 16px 0 !important;
}

// ::v-deep .el-button--success.is-plain {
//     background-color: #67C23A;
//     color: #fff;
// }

// ::v-deep .el-button--warning.is-plain {
//     background-color: #E6A23C;
//     color: #fff;
// }

// ::v-deep .el-button--primary.is-plain {
//     background-color: #409EFF;
//     color: #fff;
// }

.el-pagination {
    margin-top: 10px;
}
</style>