<template>
  <div class="page">
    <div class="content_box top">
      <div class="item">
        <div class="img_icon">
          <img src="../assets/total_profit.png" alt="" srcset="" />
        </div>
        <div class="text">
          <span>{{ homeData.amountTotal }}</span>
          <span>总交易额</span>
        </div>
      </div>
      <div class="item">
        <div class="img_icon">
          <img src="../assets/total_order.png" alt="" srcset="" />
        </div>
        <div class="text">
          <span>{{ homeData.orderTotal }}</span>
          <span>总订单数</span>
        </div>
      </div>
      <div class="item">
        <div class="img_icon">
          <img src="../assets/total_user.png" alt="" srcset="" />
        </div>
        <div class="text">
          <span>{{ homeData.userTotal }}</span>
          <span>总用户数</span>
        </div>
      </div>
      <div class="item">
        <div class="img_icon">
          <img src="../assets/toExamine_order.png" alt="" srcset="" />
        </div>
        <div class="text">
          <span>{{ homeData.orderPending }}</span>
          <span>待审核订单</span>
        </div>
      </div>
      <div class="item">
        <div class="img_icon">
          <img src="../assets/transaction_order.png" alt="" srcset="" />
        </div>
        <div class="text">
          <span>{{ homeData.orderProcess }}</span>
          <span>交易中订单</span>
        </div>
      </div>
    </div>
    <div class="content_box middle">
      <div class="middle_top_screen">
        <div class="title">实时概况</div>
        <div class="right">
          <div class="time_type_screen">
            <div class="time_type_item" :class="{ active: index == timeTypeIndex }" v-for="(item, index) in timeType"
              :key="index" @click="timeTypeClick(index)">
              {{ item.label }}
            </div>
            <div class="time_select">
              <el-date-picker v-model="timeVal" type="daterange" align="right" unlink-panels value-format="yyyy-MM-dd"
                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions2"
                @change="timeChange">
              </el-date-picker>
            </div>
          </div>
        </div>
      </div>
      <div class="charts_box">
        <div ref="chart1" style="width: 50%; height: 500px"></div>
        <div ref="chart2" style="width: 50%; height: 500px"></div>
      </div>
    </div>
    <div class="bottom">
      <div class="content_box item">
        <div class="title">
          <span>商品排行</span>
          <div class="select">
            <div class="raning_time_select" v-if="pIndex==1">
              <el-date-picker v-model="PraningTimeVal" type="month" placeholder="选择月" value-format="yyyy-MM"
                @change="getProductRanking">
              </el-date-picker>
            </div>
          <div class="tabs">
            <div class="tabs_item" :class="{'tabsActive':index==pIndex}" v-for="(item,index) in pRankingTabs"
              :key="index" @click="PtabsClick(index)">{{item}}</div>
          </div>
          </div>
        </div>
        <div class="list">
          <div class="list_item">
            <div class="child_item" v-for="(item,index) in ProductRanking" :key="index">
              <div class="raning">
                <span v-if="index==0" style="background-color: #d1433e; color: #fff;">{{index+1}}</span>
                <span v-else-if="index==1" style="background-color: #fe902e; color: #fff;">{{index+1}}</span>
                <span v-else-if="index==2" style="background-color: #e1b640; color: #fff;">{{index+1}}</span>
                <span v-else>{{index+1}}</span>
                <span>{{item.title}}</span>
              </div>
              <span>￥{{item.amountTotal}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content_box item">
        <div class="title">
          <span>提现排行</span>
           <div class="select">
            <div class="raning_time_select" v-if="wIndex==1">
              <el-date-picker v-model="WraningTimeVal" type="month" placeholder="选择月" value-format="yyyy-MM"
                @change="getWithdrawalRanking">
              </el-date-picker>
            </div>
          <div class="tabs">
            <div class="tabs_item" :class="{'tabsActive':index==wIndex}" v-for="(item,index) in wRankingTabs"
              :key="index" @click="WtabsClick(index)">{{item}}</div>
          </div>
           </div>
        </div>
        <div class="list">
          <div class="list_item">
            <div class="child_item" v-for="(item,index) in WithdrawalRanking" :key="index">
              <div class="raning">
                <span v-if="index==0" style="background-color: #d1433e; color: #fff;">{{index+1}}</span>
                <span v-else-if="index==1" style="background-color: #fe902e; color: #fff;">{{index+1}}</span>
                <span v-else-if="index==2" style="background-color: #e1b640; color: #fff;">{{index+1}}</span>
                <span v-else>{{index+1}}</span>
                <span>{{item.userName}}</span>
              </div>
              <span>￥{{item.withdrawTotal}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getHomeData, homeSales, homeUser,HomeProductRanking,HomeWithdrawalRanking} from "../request/api";
export default {
  name: "HelloWorld",
  data() {
    return {
      homeData: {},
      timeType: [
        { label: "本周", key: 0 },
        { label: "本月", key: 1 },
        { label: "本年", key: 2 },
      ],
      pRankingTabs:['总排行','月排行'],
      wRankingTabs:['总排行','月排行'],
      pIndex:0,
      wIndex:0,
      ProductRanking:[],
      WithdrawalRanking:[],
      PraningTimeVal:null,
      WraningTimeVal:null,
      pickerOptions2: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      timeVal:[],
      timeTypeIndex: 2,
      leftCharts: '',
      rightCharts: '',
      lineOption1: {
        title: {
          text: "销售趋势统计",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["交易额", "订单数"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
      },
      lineOption2: {
        title: {
          text: "客户数量统计",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["新增用户"],
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: [],
        },
        yAxis: {
          type: "value",
        },
      },
    };
  },
  created() {
    this.getTotalData();
  },
  methods: {
    PtabsClick(index){
      this.pIndex=index
      if(index==1){
        var date=new Date()
        this.PraningTimeVal=date.getFullYear()+'-'+(date.getMonth()+1>9?date.getMonth()+1:'0'+(date.getMonth()+1))
      }else{
        this.PraningTimeVal=''
      }
      this.getProductRanking()
    },
    WtabsClick(index){
      this.wIndex=index
      if(index==1){
        var date=new Date()
        this.WraningTimeVal=date.getFullYear()+'-'+(date.getMonth()+1>9?date.getMonth()+1:'0'+(date.getMonth()+1))
      }else{
        this.WraningTimeVal=''
      }
      this.getWithdrawalRanking()
    },
    async getProductRanking(){
      await HomeProductRanking({
        dataTimeStr:this.PraningTimeVal
      }).then(res=>{
        this.ProductRanking=res.data
      })
    },
    async getWithdrawalRanking(){
      await HomeWithdrawalRanking({
        dataTimeStr:this.WraningTimeVal
      }).then(res=>{
        this.WithdrawalRanking=res.data
      })
    },
    timeChange(val){
      this.timeVal=val
      this.getChartsData(0)
    },
    getTotalData() {
      getHomeData().then((res) => {
        this.homeData = res.data;
      });
    },
    async getChartsData(type) {
      let params = {
        type,
        startTimeStr:this.timeVal[0],
        endTimeStr: this.timeVal[1],
      };
      await homeSales(params).then((res) => {
        this.leftCharts = res.data;
      });
      await homeUser(params).then((res) => {
        this.rightCharts = res.data;
      });
      this.lineOption1.xAxis.data = [];
      this.lineOption1.series = [
        {
          name: "交易额",
          type: "line",
          data: [],
        },
        {
          name: "订单数",
          type: "line",
          data: [],
        },
      ];
      this.lineOption2.xAxis.data = [];
      this.lineOption2.series = [
        {
          name: "新增用户",
          type: "line",
          stack: "Total",
          data: [],
        },
      ];

      var myChart1 = echarts.init(this.$refs.chart1);
      var myChart2 = echarts.init(this.$refs.chart2);
      var option1 = this.lineOption1;
      var option2 = this.lineOption2;
      option1.series = [
        {
          name: "交易额",
          type: "line",
          data: [],
        },
        {
          name: "订单数",
          type: "line",
          data: [],
        },
      ];
      this.leftCharts.forEach((item) => {
        option1.xAxis.data.push(item.dateTime);
        option1.series[0].data.push(item.amountTotal);
        option1.series[1].data.push(item.orderTotal);
      });
      option2.series = [
        {
          name: "新增用户",
          type: "line",
          stack: "Total",
          data: [],
        },
      ];
      this.rightCharts.forEach((item) => {
        option2.xAxis.data.push(item.dateTime);
        option2.series[0].data.push(item.userNum);
      });
      // 绘制图表
      myChart1.setOption(option1);
      myChart2.setOption(option2);
    },
    timeTypeClick(index) {
      this.timeTypeIndex = index;
      this.timeVal=[]
      this.getChartsData(index + 1);
    },
  },
  mounted() {
    this.getChartsData(3);
    this.getProductRanking()
    this.getWithdrawalRanking()
  },
};
</script>

<style scoped lang="less">
  .page::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}
.page {
  padding: 10px;
  height: 1000px;
overflow-y: scroll;
  .content_box {
    box-shadow: 0 0 10px 0 #ddd;
    border-radius: 6px;
    margin-bottom: 10px;
    padding: 10px;
  }

  .top {
    height: 150px;
    display: flex;
    justify-content: space-between;

    .item {
      width: 20%;
      display: flex;
      justify-content: center;
      align-items: center;

      .img_icon {
        width: 90px;
        height: 90px;
        border-radius: 10px;
        background-color: #ecf3fa;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 50px;
          height: 50px;
        }
      }

      .text {
        height: 80px;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        span {
          display: block;
        }

        span:nth-child(1) {
          font-size: 32px;
          font-weight: bold;
        }

        span:nth-child(2) {
          color: #aaa;
        }
      }
    }
  }

  .middle {
    .middle_top_screen {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      .title {
        font-size: 24px;
      }

      .right {
        .time_type_screen {
          display: flex;
          align-items: center;

          .time_type_item {
            padding: 5px 15px;
            background-color: #f2f2f2;
            height: 30px;
            line-height: 30px;
          }

          .time_type_item:nth-child(1) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }

          .time_type_item:nth-child(3) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }

          .active {
            background-color: #409eff;
            color: #fff;
            border-color: #409eff;
          }
        }

        .time_select {
          padding: 0 10px;
        }
      }
    }

    .charts_box {
      display: flex;
      padding: 20px;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    padding-bottom: 200px;

    .item {
      width: 48%;
      min-height: 300px;

      .title {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        padding: 10px 0;
        border-bottom: 1px solid #eee;
        .select{
          display: flex;
          .raning_time_select{
            margin-right: 10px;
          }
        }
        span {
          font-size: 24px;
          font-weight: bold;
        }

        .tabs {
          display: flex;
          align-items: center;

          .tabs_item {
            padding: 5px 15px;
            height: 30px;
            line-height: 30px;
            background-color: #f2f2f2;
          }

          .tabs_item:nth-child(1) {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
          }

          .tabs_item:nth-child(2) {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
          }

          .tabsActive {
            background-color: #409eff;
            color: #fff;
          }
        }
      }

      .list {
        .list_item {
          .child_item {
            display: flex;
            justify-content: space-between;
            padding: 5px;

            .raning {
              display: flex;

              span {
                height: 20px;
                display: block;
                margin-right: 10px;
              }

              span:nth-child(1) {
                width: 20px;
                text-align: center;
                line-height: 20px;
                background-color: #f2f2f2;
                padding: 5px;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
