import { GET, POST, DELETE, PUT } from "./http";
// 登录
export const login = (params) =>
  POST(
    `/api/admin/login/smslogin?phonenumber=${params.phonenumber}&msgCode=${params.msgCode}`
  );
// 用户列表
export const UserList = (params) =>
  GET("/api/system/user/findUserList", params);
// 获取验证码
export const msgCode = (params) => GET("/api/admin/login/sendMsgCode", params);
// 订单列表
export const OrderList = (params) =>
  GET("/api/transaction/selectTransactionListPage", params);
// 订单详情
export const orderDetails = (params) =>
  GET("/api/transaction/selectById", params);
// 商品分类列表
export const commodityTitleList = (params) =>
  GET("/api/product/selectProductList", params);
// 商品列表
export const commodityList = (params) =>
  GET("/api/product/selectProductListPage", params);
// 修改订单状态
export const updateOrder = (params) =>
  POST("/api/transaction/updateStatus", params);
// 提现管理
export const Withdrawal = (params) =>
  GET("/api/withdrawalRecord/selectWithdrawalRecordListPage", params);
// 修改提现状态
export const updateWithdrawal = (params) =>
  PUT("/api/withdrawalRecord/updateWithdrawalRecord", params);
// 导出订单
export const orderExport = (params) => GET("/api/transaction/export", params);
// 删除商品
export const deleteCommodity = (params) =>
  DELETE("/api/product/deleteProduct", params);
// 编辑商品
export const putCommodity = (params) =>
  PUT("/api/product/updateProduct", params);
// 新增商品
export const addCommoduty = (params) =>
  POST("/api/product/insertProduct", params);
// 操作日志
export const OperationLogList = (params) =>
  GET("/api/monitor/operlog/list", params);
// 新增商品分类
export const addCommodutyTitle = (params) =>
  POST("/api/product/insertProductClass", params);
// 删除商品分类
export const deletaCommoduyuTile = (params) =>
  DELETE("/api/product/deleteProductClass", params);
// 首页总览
export const getHomeData= (params)=>GET('/api/pc/index/statistics/overview',params)
// 首页销售趋势
export const homeSales=(params)=>GET('/api/pc/index/statistics/sales',params)
// 首页用户统计
export const homeUser=(params)=>GET('/api/pc/index/statistics/user',params)
// 首页商品排行
export const HomeProductRanking=(params)=>GET('/api/pc/index/product/rank',params)
// 首页提现排行
export const HomeWithdrawalRanking=(params)=>GET('/api/pc/index/withdraw/rank',params)
